import React, { useEffect, useState, forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

registerLocale('fr', fr);

const DateRangeInput = ({
  initialValue,
  onValueChanged,
  minimumReservationDuration = 901,
  timezone = 'Europe/Paris',
  withPortal = false,
  language = 'fr'
}) => {
  const { t } = useTranslation(['common']);
  const [startDate, setStartDate] = useState(initialValue.startDate);
  const [endDate, setEndDate] = useState(initialValue.endDate);

  useEffect(() => {
    if (typeof onValueChanged === 'function') {
      onValueChanged({
        startDate,
        endDate
      });
    }
  }, [startDate, endDate]);

  const StartDateInput = ({ value, onClick }, ref) => (
    <TextField
      inputRef={ref}
      variant="outlined"
      label="debut"
      margin="normal"
      fullWidth
      value={value}
      onClick={onClick}
    />
  );

  const EndDateInput = ({ value, onClick }, ref) => (
    <TextField
      inputRef={ref}
      variant="outlined"
      label="fin"
      margin="normal"
      fullWidth
      value={value}
      onClick={onClick}
    />
  );

  const handleChangeStartDate = date => {
    setStartDate(date);
  };

  const CustomStartInput = forwardRef(StartDateInput);
  const CustomEndInput = forwardRef(EndDateInput);

  return (
    <React.Fragment>
      <Grid item xs={6} md={2} lg={2}>
        <DatePicker
          locale={'fr'}
          selected={startDate}
          onChange={date => handleChangeStartDate(date)}
          showTimeSelect
          timeCaption={'Heure'}
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="d MMM yyyy HH:mm"
          customInput={<CustomStartInput />}
          withPortal={withPortal}
        />
      </Grid>

      <Grid item xs={6} md={3} lg={2}>
        <DatePicker
          locale={'fr'}
          selected={endDate}
          onChange={date => setEndDate(date)}
          showTimeSelect
          timeCaption={'Heure'}
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="d MMM yyyy HH:mm"
          customInput={<CustomEndInput />}
          popperPlacement="auto"
          popperModifiers={{
            flip: {
              enabled: false
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false
            }
          }}
          withPortal={withPortal}
        />
      </Grid>
    </React.Fragment>
  );
};

export default DateRangeInput;
