import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { I18n } from 'aws-amplify';

import logo from '../../assets/images/logo-retina.png';
import config from '../../config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    }
  })
);

const ToolAppBar = ({ history, signOut = false }) => {
  const classes = useStyles();
  I18n.setLanguage('fr');

  useEffect(() => {
    const dict = {
      fr: {
        'Sign Out': 'Se deconnecter'
      }
    };

    onAuthUIStateChange(async (nextAuthState, authData) => {
      if (nextAuthState == 'signedout')
        history.push(`${config.auth.rootUrl}login`);
    });

    I18n.putVocabularies(dict);
  }, []);

  const onClickLogo = () => {
    history.push(`${config.auth.rootUrl}`);
  };

  return (
    <AppBar className={classes.root} className="app-bar" position="relative">
      <Container maxWidth="lg">
        <Toolbar>
          <img
            onClick={onClickLogo}
            className="logo"
            src={logo}
            alt={'logo'}
          ></img>
          <Typography className="title" variant="h5" color="inherit" noWrap>
            État des lieux
          </Typography>
          {signOut ? <AmplifySignOut></AmplifySignOut> : null}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default withRouter(ToolAppBar);
