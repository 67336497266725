import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import ToolAppBar from '../components/nav/ToolAppBar';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';

import DateRangeInput from '../components/DateRangeInput';
import { dateLongFormat } from '../utils/variables';
import { findReservations } from '../store/actions/damage_report';
import AsyncContent from '../components/shared/AsyncContent';
import config from '../config';

import {
  CHANGE_PAGE,
  CHANGE_ROW_PER_PAGE,
  SEARCH_FIRST_NAME,
  SEARCH_LAST_NAME,
  SEARCH_DATE,
  SEARCH_RESERVATION_ID,
  SEARCH_CONTRAT_ID,
  SEARCH_VEHICLE_ID
} from '../store/actions/damage_report';

const columns = [
  { id: 'reservation_id', label: 'Réservation', minWidth: 150 },
  { id: 'contrat_id', label: 'Contrat', minWidth: 150 },
  { id: 'start', label: 'EDL départ', minWidth: 110 },
  { id: 'end', label: 'EDL fin', minWidth: 90 },
  {
    id: 'reservation_start_at',
    label: 'Départ',
    format: value => moment(value).format(dateLongFormat),
    minWidth: 180
  },
  {
    id: 'reservation_end_at',
    label: 'Fin',
    format: value => moment(value).format(dateLongFormat),
    minWidth: 180
  },
  { id: 'driver_firstname', label: 'Conducteur Prénom', minWidth: 90 },
  { id: 'driver_lastname', label: 'Conducteur Nom', minWidth: 90 },
  { id: 'customer_firstname', label: 'Client Prénom', minWidth: 90 },
  { id: 'customer_lastname', label: 'Client Nom', minWidth: 90 },
  { id: 'location_start_code', label: 'Location Départ' },
  { id: 'location_end_code', label: 'Location Fin' },
  { id: 'vehicle_plate', label: 'Véhicule' }
];

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 650
  },
  button: {
    marginTop: '7px',
    marginLeft: '12px'
  }
});

function DamageReports({ history }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['damage_report']);
  const classes = useStyles();
  const rowPerPage = [50, 100, 200];
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setisFirstLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const reservations = useSelector(state => state.damage_report.reservations);
  const page = useSelector(state => state.damage_report.page);
  const limit = useSelector(state => state.damage_report.limit);
  const total = useSelector(state => state.damage_report.total);
  const startDate = useSelector(state => state.damage_report.startDate);
  const endDate = useSelector(state => state.damage_report.endDate);
  const searchVechileId = useSelector(
    state => state.damage_report.searchVechileId
  );
  const searchReservationId = useSelector(
    state => state.damage_report.searchReservationId
  );
  const searchContratId = useSelector(
    state => state.damage_report.searchContratId
  );
  const firstName = useSelector(state => state.damage_report.firstName);
  const lastName = useSelector(state => state.damage_report.lastName);

  useEffect(() => {
    async function fetchData() {
      await loadData();
      setisFirstLoading(false);
    }
    fetchData();
  }, [page, limit]);

  const handleChangePage = async (event, newPage) => {
    dispatch({
      type: CHANGE_PAGE,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = async event => {
    dispatch({
      type: CHANGE_ROW_PER_PAGE,
      limit: +event.target.value
    });
  };

  const loadData = async () => {
    setIsLoading(true);
    await dispatch(
      findReservations(
        page,
        limit,
        startDate,
        endDate,
        searchReservationId,
        searchContratId,
        searchVechileId,
        firstName,
        lastName
      )
    );
    setIsLoading(false);
  };

  const submitSearch = async edlvalue => {
    await loadData();
  };

  const resetSearch = () => {
    window.location.reload(false);
  };

  const onChangeReservation = event => {
    dispatch({
      type: SEARCH_RESERVATION_ID,
      searchReservationId: event.target.value
    });
  };

  const onChangeContrat = event => {
    dispatch({
      type: SEARCH_CONTRAT_ID,
      searchContratId: event.target.value
    });
  };

  const onChangeVehicle = event => {
    dispatch({
      type: SEARCH_VEHICLE_ID,
      searchVechileId: event.target.value
    });
  };

  const onChangeFirstName = event => {
    dispatch({
      type: SEARCH_FIRST_NAME,
      firstName: event.target.value
    });
  };

  const onChangeLastName = event => {
    dispatch({
      type: SEARCH_LAST_NAME,
      lastName: event.target.value
    });
  };

  const handleDateRange = ({ startDate, endDate }) => {
    dispatch({
      type: SEARCH_DATE,
      startDate: startDate,
      endDate: endDate
    });
  };

  const handleEdlLink = (uuid, type, bugRouter) => {
    history.push(
      `${config.auth.rootUrl}etat-des-lieux/${bugRouter}/${uuid}/${type}`
    );
  };

  const edlLink = (type, uuid, edlvalue) => {
    const start = _.find(edlvalue, { type: type });
    const end = _.find(edlvalue, { type: type });

    if (start || end)
      return (
        <span
          className="link"
          onClick={handleEdlLink.bind(
            this,
            uuid,
            type,
            type == 'start' ? 'debut' : 'fin'
          )}
        >
          Voir EDL
        </span>
      );
    else return <span>n/a</span>;
  };

  const tableCellValue = (column, reservation, value) => {
    if (column.id === 'start') {
      return edlLink(
        'start',
        reservation['uuid'],
        reservation['damage_reports']
      );
    } else if (column.id === 'end') {
      return edlLink('end', reservation['uuid'], reservation['damage_reports']);
    } else {
      return column.format ? column.format(value) : value;
    }
  };

  const tableResult = () => {
    return (
      <AsyncContent loading={!isLoading && isFirstLoading}>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reservations.map(reservation => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={reservation.id}
                    >
                      {columns?.map(column => {
                        const value = reservation[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {tableCellValue(column, reservation, value)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowPerPage}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </AsyncContent>
    );
  };

  return (
    <div className="">
      <ToolAppBar signOut={true} />
      <br />

      <Container component="main" maxWidth="lg">
        <Card className={classes.root}>
          <CardContent>
            <Grid spacing={2} className={''} container>
              <DateRangeInput
                withPortal={isMobile}
                language={'fr'}
                initialValue={{
                  startDate: startDate,
                  endDate: endDate
                }}
                onValueChanged={handleDateRange}
              />

              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  id="reservation"
                  name="reservation"
                  variant="outlined"
                  label={t('damage_report:label-reservation')}
                  margin="normal"
                  fullWidth
                  onChange={onChangeReservation}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  id="contrat"
                  name="contrat"
                  variant="outlined"
                  label={t('damage_report:label-contrat')}
                  margin="normal"
                  fullWidth
                  onChange={onChangeContrat}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  id="vehicle"
                  name="vehicle"
                  variant="outlined"
                  label={t('damage_report:label-vehicle')}
                  margin="normal"
                  fullWidth
                  onChange={onChangeVehicle}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  id="first_name"
                  name="first_name"
                  variant="outlined"
                  label={t('damage_report:label-first-name')}
                  margin="normal"
                  fullWidth
                  onChange={onChangeFirstName}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  id="last_name"
                  name="last_name"
                  variant="outlined"
                  label={t('damage_report:label-last-name')}
                  margin="normal"
                  fullWidth
                  onChange={onChangeLastName}
                />
              </Grid>
              <Grid
                alignItems="center"
                justify="center"
                container
                item
                xs={6}
                md={3}
                lg={2}
              >
                <Grid item xs={3}>
                  <IconButton onClick={resetSearch} aria-label="delete">
                    <RotateLeftIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={9}>
                  <Button
                    onClick={submitSearch}
                    className={classes.button}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Rechercher
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br />

        {isLoading ? (
          <CircularProgress size={80} className="refresh-loader" />
        ) : null}
        {reservations.length === 0 && !isLoading ? (
          <p className="no-reservations-found">
            {t('damage_report:search-no-result')}
          </p>
        ) : (
          tableResult()
        )}
      </Container>
    </div>
  );
}

export default withRouter(DamageReports);
