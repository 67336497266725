import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const AsyncContent = ({ children, loading = false }) => {
  return (
    <div className="async-content">
      {loading ? <CircularProgress /> : children}
    </div>
  );
};

export default AsyncContent;
