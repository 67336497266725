import React, { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import CenteredLoader from './components/shared/CenteredLoader';
import cargo from './themes/cargo';
import MainComponent from './MainComponent';

function Front() {
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const theme = createMuiTheme(cargo);
  const [isLoading, setIsLoading] = useState(true);

  const loadData = async () => {
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        ref={notistackRef}
        maxSnack={3}
        preventDuplicate
        action={key => (
          <IconButton
            className="alert-delete-button"
            onClick={onClickDismiss(key)}
            aria-label="delete"
          >
            <CloseIcon style={{ fill: 'white' }} />
          </IconButton>
        )}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <div className="App tenant-theme">
          <CssBaseline />
          {isLoading ? (
            <CenteredLoader />
          ) : (
            <Router>
              <MainComponent theme={theme} />
            </Router>
          )}
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default Front;
