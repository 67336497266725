import React, { useEffect, useState } from 'react';

import AppRoutes from './AppRoutes';
import CenteredLoader from './components/shared/CenteredLoader';
import { useLanguageLoader } from './hooks/languageLoader';
import Amplify from 'aws-amplify';

const MainComponent = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(true);
  useLanguageLoader();

  const loadData = async () => {
    Amplify.configure({
      region: process.env.REACT_APP_AWS_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      userPoolWebClientId:
        process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
      identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID
    });

    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return !isLoading ? (
    <main>
      <AppRoutes />
    </main>
  ) : (
    <CenteredLoader />
  );
};
export default MainComponent;
