import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';

import { authenticateUser } from '../../store/actions/user';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(true);
  const [isAuthenticated, setisAuthenticated] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          const currentSession = await Auth.currentSession();
          dispatch(authenticateUser(currentSession.idToken.jwtToken));
          setisAuthenticated(true);
        }
      } catch (error) {
        setisAuthenticated(false);
      }
      setisLoading(false);
    }
    fetchData();
  }, []);

  const component = () => {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  };

  return !isLoading ? component() : <div></div>;
};

export default PrivateRoute;
