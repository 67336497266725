import httpClient from '../httpClient';

export default {
  findReservations(
    page,
    limit,
    startDate,
    endDate,
    searchReservationId,
    searchContratId,
    searchVechileId,
    firstName,
    lastName
  ) {
    let url = `/reservations?page=${page + 1}&limit=${limit}`;

    if (searchReservationId) url += `&reservation_id=${searchReservationId}`;
    if (searchContratId) url += `&contrat_id=${searchContratId}`;
    if (searchVechileId) url += `&vehicle_id=${searchVechileId}`;
    if (firstName) url += `&firstname=${firstName}`;
    if (lastName) url += `&lastname=${lastName}`;

    url += `&reservation_start_at=${startDate}`;
    url += `&reservation_end_at=${endDate}`;

    return httpClient().get(url);
  },
  findReservation(uuid) {
    return httpClient().get(`/reservations/${uuid}`);
  }
};
