import api from '../../utils/api';
import moment from 'moment-timezone';
import { overrideTimezone } from '../../utils/dateHelper';

export const FIND_RESERVATION = 'FIND_RESERVATION';
export const FIND_RESERVATIONS = 'FIND_RESERVATIONS';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_ROW_PER_PAGE = 'CHANGE_ROW_PER_PAGE';
export const SEARCH_RESERVATION_ID = 'SEARCH_RESERVATION_ID';
export const SEARCH_CONTRAT_ID = 'SEARCH_CONTRAT_ID';
export const SEARCH_VEHICLE_ID = 'SEARCH_VEHICLE_ID';
export const SEARCH_LAST_NAME = 'SEARCH_LAST_NAME';
export const SEARCH_DATE = 'SEARCH_DATE';
export const SEARCH_FIRST_NAME = 'SEARCH_FIRST_NAME';
export const RESET_SEARCH = 'RESET_SEARCH';

export function findReservation(uuid) {
  return async function(dispatch) {
    try {
      const response = await api.damage_report.findReservations(uuid);

      dispatch({
        type: FIND_RESERVATIONS,
        reservations: response.reservations,
        total: response.total
      });
    } catch (error) {
      throw error;
    }
  };
}

export function findReservations(
  page,
  limit,
  startDate,
  endDate,
  searchReservationId,
  searchContratId,
  searchVechileId,
  firstName,
  lastName
) {
  return async function(dispatch) {
    try {
      const response = await api.damage_report.findReservations(
        page,
        limit,
        overrideTimezone(startDate).toISOString(),
        overrideTimezone(endDate).toISOString(),
        searchReservationId,
        searchContratId,
        searchVechileId,
        firstName,
        lastName
      );

      dispatch({
        type: FIND_RESERVATIONS,
        reservations: response.reservations,
        total: response.total
      });
    } catch (error) {
      throw error;
    }
  };
}
