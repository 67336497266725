import { combineReducers } from 'redux';
import app from './app';
import user from './user';
import damage_report from './damage_report';

export default combineReducers({
  app,
  user,
  damage_report
});
