import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';

import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { Hub } from 'aws-amplify';
import { I18n } from 'aws-amplify';

import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import ToolAppBar from '../components/nav/ToolAppBar';
import { authenticateUser } from '../store/actions/user';

function LoginPage({ history }) {
  const { t } = useTranslation(['error']);
  const dispatch = useDispatch();
  const [authState, setAuthState] = useState(null);
  const [user, setUser] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  I18n.setLanguage('fr');

  useEffect(() => {
    const dict = {
      fr: {
        'Sign In': 'Se connecter',
        'Sign Up': "S'inscrire",
        'Send Code': 'Envoyer le code',
        'Sign in to your account':
          'Connectez-vous à la plateforme Gestionnaire',
        'Address email *': 'Addresse email *',
        'Email Address *': 'Addresse email *',
        'Password *': 'Mot de passe *',
        'Enter your email address': 'Entrez votre email',
        'Enter your password': 'Entrez votre mot de passe',
        'Forgot your password?': 'Mot de passe oublié ? ',
        'Reset password': 'Réinitialiser le mot de passe',
        'Back to Sign In': 'Retour a se connecter',
        'Reset your password': 'Réinitialiser le mot de passe',
        'Username *': 'Entrez votre adresse email *',
        'Enter your username': 'Entrez votre adresse email',
        'Verification code': 'Code',
        'New password': 'Nouveau mot de passe',
        'Enter code': 'Entrez votre code',
        'Enter your new password': 'Entrez votre mot de passe',
        Submit: 'Envoyer'
      }
    };

    I18n.putVocabularies(dict);
  }, []);

  useEffect(() => {
    if (authState === AuthState.SignedIn) {
      window.location.href = window.location.origin;
    }
  }, [authState]);

  useEffect(() => {
    const setUserInformation = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          const currentSession = await Auth.currentSession();
          dispatch(authenticateUser(currentSession.idToken.jwtToken));
        }
      } catch (error) {}
    };

    onAuthUIStateChange(async (nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      await setUserInformation();
    });
    Hub.listen('auth', res => {
      const messageCode = res.payload.data.name ? res.payload.data.name : null;
      if (messageCode) {
        enqueueSnackbar(t(`validation:${res.payload.data.name}`), {
          variant: 'error'
        });
      }
    });
  }, []);

  return (
    <div className="">
      <ToolAppBar />
      {authState === AuthState.SignedIn && user ? (
        <CircularProgress className="refresh-loader" />
      ) : null}
      <Container component="main" maxWidth="md">
        <AmplifyAuthenticator>
          <AmplifySignIn
            slot="sign-in"
            usernameAlias="email"
            hideSignUp
          ></AmplifySignIn>
        </AmplifyAuthenticator>
      </Container>
    </div>
  );
}

export default withRouter(LoginPage);
