import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function CenteredLoader() {
  return (
    <div className="centered-loader full-size flex-centered">
      <CircularProgress />
    </div>
  );
}

export default CenteredLoader;
