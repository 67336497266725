import api from '../../utils/api';

export const AUTH_LOGGED_IN = 'AUTH_LOGGED_IN';
export const AUTH_LOGGED_OUT = 'AUTH_LOGGED_OUT';
export const ACCOUNT_USER = 'ACCOUNT_USER';

export function authenticateUser(token) {
  return async function(dispatch) {
    try {
      dispatch({
        type: AUTH_LOGGED_IN,
        access_token: token
      });

      localStorage.setItem('token', token);
    } catch (error) {
      throw error;
    }
  };
}

export function logOut(dispatchLogout) {
  return async function(dispatch) {
    try {
      localStorage.removeItem('token');
    } catch (error) {
      throw error;
    }
  };
}
