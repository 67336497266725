import axios from 'axios';
import _ from 'lodash';
import config from '../config';

export default function() {
  const http = axios.create({
    baseURL: _.trimEnd(config.api.url, '/')
  });

  http.defaults.headers.common['Content-Type'] = 'application/json';
  // http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

  http.interceptors.request.use(
    request => {
      const token = localStorage.getItem('token');

      if (token) {
        request.headers['Authorization'] = token;
      }

      return request;
    },
    error => Promise.reject(error)
  );

  http.interceptors.response.use(
    response => Promise.resolve(response.data),
    error => {
      let type = 'internal';

      const codeTypeMapping = {
        400: 'bad_request',
        401: 'unauthorized',
        402: 'payment',
        403: 'forbidden',
        404: 'not_found',
        409: 'conflict'
      };

      if (
        error.response &&
        error.response.status &&
        codeTypeMapping[error.response.status]
      ) {
        type = codeTypeMapping[error.response.status];
      }

      let errors = null;
      if (error.response && error.response.data) {
        errors = error.response.data.message
          ? [
              {
                code: error.response.data.code,
                detail: error.response.data.message
              }
            ]
          : error.response.data.errors;
      }

      return Promise.reject({
        type,
        code:
          error.response && error.response.status
            ? error.response.status
            : null,
        response: error.response,
        errors
      });
    }
  );

  return http;
}
