import {} from '../actions/damage_report';
import moment from 'moment-timezone';

import {
  SEARCH_DATE,
  FIND_RESERVATIONS,
  RESET_SEARCH,
  CHANGE_PAGE,
  CHANGE_ROW_PER_PAGE,
  SEARCH_LAST_NAME,
  SEARCH_FIRST_NAME,
  SEARCH_RESERVATION_ID,
  SEARCH_CONTRAT_ID,
  SEARCH_VEHICLE_ID
} from '../actions/damage_report';

const initialState = {
  reservations: [],
  total: 0,
  page: 0,
  limit: 50,
  searchVechileId: null,
  searchContratId: null,
  startDate: moment()
    .subtract('months', 1)
    .toDate(),
  endDate: moment()
    .add(1, 'months')
    .toDate(),
  firstName: null,
  lastName: null,
  searchReservationId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FIND_RESERVATIONS:
      return {
        ...state,
        reservations: action.reservations,
        total: action.total
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page
      };
    case CHANGE_ROW_PER_PAGE:
      return {
        ...state,
        limit: action.limit
      };
    case SEARCH_DATE:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        page: 0
      };
    case SEARCH_LAST_NAME:
      return {
        ...state,
        lastName: action.lastName,
        page: 0
      };
    case SEARCH_FIRST_NAME:
      return {
        ...state,
        firstName: action.firstName,
        page: 0
      };
    case SEARCH_RESERVATION_ID:
      return {
        ...state,
        searchReservationId: action.searchReservationId,
        page: 0
      };
    case SEARCH_CONTRAT_ID:
      return {
        ...state,
        searchContratId: action.searchContratId,
        page: 0
      };
    case SEARCH_VEHICLE_ID:
      return {
        ...state,
        searchVechileId: action.searchVechileId,
        page: 0
      };
    case RESET_SEARCH:
      return {
        ...state,
        page: 0,
        limit: 5,
        searchVechileId: null,
        searchReservationId: null
      };
    default:
      return state;
  }
};
