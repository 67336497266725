import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import AWS from 'aws-sdk';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { Auth } from 'aws-amplify';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import AsyncContent from '../components/shared/AsyncContent';
import ToolAppBar from '../components/nav/ToolAppBar';
import api from '../utils/api';
import { dateLongFormat } from '../utils/variables';
import config from '../config';

const useStyles = makeStyles({
  root: {},
  media: {
    height: 140
  },
  table: {
    minWidth: 650
  }
});

function DamageReport({ history }) {
  const { uuid } = useParams();
  const { type } = useParams();
  const { t } = useTranslation(['error']);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [reservation, setReservation] = useState({});
  const [damageReport, setDamageReport] = useState({});
  const [pictures, setPictures] = useState([]);
  let picturesArray = [];

  useEffect(() => {
    const loadData = async () => {
      const response = await api.damage_report.findReservation(uuid);
      const damageReportResponse = _.find(response.reservation.damage_reports, {
        type: type
      });
      setReservation(response.reservation);
      setDamageReport(damageReportResponse);
      setS3URL(damageReportResponse.pictures);
      setIsLoading(false);
    };

    async function fetchData() {
      await loadData();
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function loadAws() {
      let currentSession = await Auth.currentSession();
      let loginMap = {};

      AWS.config.region = process.env.REACT_APP_AWS_COGNITO_REGION;
      loginMap[
        'cognito-idp.' +
          process.env.REACT_APP_AWS_COGNITO_REGION +
          '.amazonaws.com/' +
          process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID
      ] = currentSession.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
        Logins: loginMap
      });

      await getAwsConfig();
    }
    loadAws();
  }, []);

  const setS3URL = async pictures => {
    for (let i = 0; i < pictures.length; i++) {
      let element = {
        id: pictures[i].id,
        type: t(`damage_report:${pictures[i].type}`),
        url: await generateS3Url(pictures[i].key)
      };
      picturesArray.push(element);
    }
    setPictures(picturesArray);
  };

  async function getAwsConfig() {
    return new Promise(function(resolve, reject) {
      AWS.config.getCredentials(async function(err) {
        if (err) {
          return reject(err);
        } else {
          resolve({
            accessKey: AWS.config.credentials.accessKeyId,
            secretKey: AWS.config.credentials.secretAccessKey,
            sessionToken: AWS.config.credentials.sessionToken
          });
        }
      });
    });
  }

  const generateS3Url = async key => {
    const client = new S3Client({
      region: process.env.REACT_APP_AWS_COGNITO_REGION,
      credentials: {
        accessKeyId: AWS.config.credentials.accessKeyId,
        secretAccessKey: AWS.config.credentials.secretAccessKey,
        sessionToken: AWS.config.credentials.sessionToken
      }
    });

    const command = new GetObjectCommand({
      Bucket: process.env.REACT_APP_AWS_S3_STORAGE_BUCKET_NAME,
      Key: key
    });
    const url = await getSignedUrl(client, command, { expiresIn: 3600 });

    return url;
  };

  const googleMapLink = () => {
    if (damageReport && damageReport.latitude && damageReport.longitude)
      window.open(
        `https://maps.google.com/?q=${damageReport.latitude},${damageReport.longitude}`
      );
  };

  const openImage = picture => {
    if (picture) window.open(picture.url);
  };

  const showPictures = () => {
    return (
      <TableBody>
        {pictures.map(picture => (
          <TableRow key={picture.id}>
            <TableCell align="left">{picture.type}</TableCell>
            <TableCell onClick={openImage.bind(this, picture)} align="right">
              <span className="link">Voir photo</span>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const handleEdlLink = (uuid, type, bugRouter) => {
    history.push(
      `${config.auth.rootUrl}etat-des-lieux/${bugRouter}/${uuid}/${type}`
    );
  };

  const edlLink = (type, uuid) => {
    const start = _.find(reservation.damage_reports, { type: 'start' });
    const end = _.find(reservation.damage_reports, { type: 'end' });

    if (type === 'start' && end)
      return (
        <span
          className="link"
          onClick={handleEdlLink.bind(this, uuid, 'end', 'fin')}
        >
          Lien vers l'EDL de fin
        </span>
      );
    else if (type === 'end' && start) {
      return (
        <span
          className="link"
          onClick={handleEdlLink.bind(this, uuid, 'start', 'debut')}
        >
          Lien vers l'EDL de début
        </span>
      );
    }
  };

  return (
    <div className="">
      <ToolAppBar signOut={true} />
      <br />
      <br />
      <br />
      <AsyncContent loading={isLoading}>
        <Container component="main" maxWidth="lg">
          <Typography gutterBottom variant="h4">
            Etat des lieux de {damageReport.type == 'start' ? 'début' : 'fin'}{' '}
            de réservation
          </Typography>
          {edlLink(damageReport.type, reservation.uuid)}
          <br />
          <br />
          <Grid
            className={classes.buttonGrp}
            spacing={2}
            container
            justify="center"
          >
            <Grid item xs={5}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Informations de L'EDL
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    Auteur : {damageReport.completed_by}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    Date :{' '}
                    {moment(damageReport.created_at).format(dateLongFormat)}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    Localisation :{' '}
                    <a className="link" onClick={googleMapLink}>
                      Lien google map
                    </a>
                  </Typography>
                </CardContent>
              </Card>
              <br />
              <Card className={classes.root}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Détails de réservation
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    Réservation :{' '}
                    {reservation.reservation_id
                      ? reservation.reservation_id
                      : 'N/A'}
                    <br />
                    Contrat :{' '}
                    {reservation.reservation_id
                      ? reservation.reservation_id
                      : 'N/A'}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    Clients :{' '}
                    {reservation.customer_firstname
                      ? reservation.customer_firstname
                      : 'N/A'}{' '}
                    {reservation.customer_lastname
                      ? reservation.customer_lastname
                      : 'N/A'}
                    <br />
                    Conducteur :{' '}
                    {reservation.driver_firstname
                      ? reservation.driver_firstname
                      : 'N/A'}{' '}
                    {reservation.driver_lastname
                      ? reservation.driver_lastname
                      : 'N/A'}
                    <br />
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    Date de depart :{' '}
                    {moment(reservation.reservation_start_at).format(
                      dateLongFormat
                    )}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    Date de retour :{' '}
                    {moment(reservation.reservation_end_at).format(
                      dateLongFormat
                    )}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    Agence départ :{' '}
                    {reservation.location_start_code
                      ? reservation.location_start_code
                      : 'N/A'}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    Agence retour :{' '}
                    {reservation.location_end_code
                      ? reservation.location_end_code
                      : 'N/A'}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    Véhicule :{' '}
                    {reservation.vehicle_plate
                      ? reservation.vehicle_plate
                      : 'N/A'}{' '}
                    -{' '}
                    {reservation.vehicle_make
                      ? reservation.vehicle_make
                      : 'N/A'}{' '}
                    -{' '}
                    {reservation.vehicle_model
                      ? reservation.vehicle_model
                      : 'N/A'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={7}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="right">Url</TableCell>
                    </TableRow>
                  </TableHead>
                  {showPictures()}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </AsyncContent>
    </div>
  );
}

export default withRouter(DamageReport);
