import React from 'react';
import { Route } from 'react-router-dom';

import PrivateRoute from './components/routing/PrivateRoute';
import LoginPage from './views/LoginPage';
import DamageReport from './views/DamageReport';
import DamageReports from './views/DamageReports';
import config from './config';

const Routes = ({ tenant }) => {
  return (
    <div>
      <Route exact path={`${config.auth.rootUrl}login`}>
        <LoginPage />
      </Route>
      <PrivateRoute
        exact
        path={`${config.auth.rootUrl}`}
        component={DamageReports}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path={`${config.auth.rootUrl}etat-des-lieux/debut/:uuid/:type`}
        component={DamageReport}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path={`${config.auth.rootUrl}etat-des-lieux/fin/:uuid/:type`}
        component={DamageReport}
      ></PrivateRoute>
    </div>
  );
};

export default Routes;
