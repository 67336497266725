import { AUTH_LOGGED_IN, AUTH_LOGGED_OUT, ACCOUNT_USER } from '../actions/user';

const initialState = {
  isAuthenticated: false,
  access_token: null,
  user: null,
  groups: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGGED_IN:
      return {
        ...state,
        isAuthenticated: true,
        access_token: action.token
      };
    case AUTH_LOGGED_OUT:
      return {
        ...state,
        isAuthenticated: false,
        access_token: null
      };
    case ACCOUNT_USER:
      return {
        ...state,
        isAuthenticated: true
      };
    default:
      return state;
  }
};
