import React from 'react';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.log('error', error);
  }

  render() {
    if (this.state.hasError) {
      return <h2 className="error-message-rollbar">error</h2>;
    }
    return this.props.children;
  }
}

export default withTranslation('common')(ErrorBoundary);
